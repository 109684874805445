var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Physical person")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchFilter,
      expression: "searchFilter"
    }],
    staticClass: "min-w-300px form-control form-control-solid mr-4",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('Search')
    },
    domProps: {
      "value": _vm.searchFilter
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchFilter = $event.target.value;
      }
    }
  }), _c('euro-select', {
    staticClass: "min-w-200px mr-4 mb-0",
    attrs: {
      "multiselect-props": _vm.multiselectProps
    },
    model: {
      value: _vm.itemFilter,
      callback: function callback($$v) {
        _vm.itemFilter = $$v;
      },
      expression: "itemFilter"
    }
  }), _c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'create-physical-person'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Communication/Add-user.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create")) + " ")])])], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.name",
      fn: function fn(_ref) {
        var _item$name, _item$surname;
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-capitalize d-flex align-items-center"
        }, [_c('Avatar', {
          attrs: {
            "avatar-image": item === null || item === void 0 ? void 0 : item.avatar_display,
            "avatar-text": "".concat(item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name[0]).concat(item === null || item === void 0 ? void 0 : (_item$surname = item.surname) === null || _item$surname === void 0 ? void 0 : _item$surname[0])
          }
        }), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "font-weight-bold",
          style: {
            wordBreak: 'break-word'
          }
        }, [_vm._v(" " + _vm._s("".concat(item === null || item === void 0 ? void 0 : item.name, " ").concat(item === null || item === void 0 ? void 0 : item.surname)) + " ")])])], 1)];
      }
    }, {
      key: "cell.type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.is_temporary_employee ? _c('div', {
          staticClass: "label label-inline font-weight-bold label-light-warning"
        }, [_vm._v(" " + _vm._s(_vm.$t("Temporary worker")) + " ")]) : item.is_employee ? _c('div', {
          staticClass: "label label-inline font-weight-bold label-light-success"
        }, [_vm._v(" " + _vm._s(_vm.$t("Employee")) + " ")]) : _c('div', {
          staticClass: "label label-inline font-weight-bold label-light-primary"
        }, [_vm._v(" " + _vm._s(_vm.$t("Standard person")) + " ")]), item.is_client_on_secondments ? _c('div', {
          staticClass: "ml-3 label label-inline font-weight-bold label-light-info"
        }, [_vm._v(" " + _vm._s(_vm.$t("Client")) + " ")]) : _vm._e(), item.is_partner_on_secondments ? _c('div', {
          staticClass: "ml-3 label label-inline font-weight-bold label-light-info"
        }, [_vm._v(" " + _vm._s(_vm.$t("Partner")) + " ")]) : _vm._e(), item.is_contractor_on_secondments ? _c('div', {
          staticClass: "ml-3 label label-inline font-weight-bold label-light-info"
        }, [_vm._v(" " + _vm._s(_vm.$t("Contractor")) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell.main_address",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.physicalpersonaddress_set.length ? _c('div', [_c('div', {
          staticClass: "font-weight-normal"
        }, [_vm._v(_vm._s(_vm.getMainAddress(item)))]), _c('div', {
          staticClass: "font-weight-normal text-muted"
        }, [_vm._v(_vm._s(_vm.getMainAddressDescription(item)))])]) : _vm._e()];
      }
    }, {
      key: "cell.tel_contact",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.telcontact_set.length ? _c('div', [_c('div', {
          staticClass: "font-weight-normal"
        }, [_vm._v(_vm._s(_vm.getMainTel(item)))]), _c('div', {
          staticClass: "font-weight-normal text-muted"
        }, [_vm._v(_vm._s(_vm.getMainTelKind(item)))])]) : _vm._e()];
      }
    }, {
      key: "cell.extraemail_set",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.extraemail_set.length ? _c('div', {
          staticClass: "max-width-column"
        }, [_c('div', {
          staticClass: "font-weight-normal"
        }, [_vm._v(_vm._s(item.extraemail_set[0].email))])]) : _c('div')];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('View Details')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-sm",
          class: {
            'btn-hover-primary': _vm.isDeletableUser(item)
          },
          attrs: {
            "title": _vm.isDeletableTooltip(item)
          },
          on: {
            "click": function click($event) {
              _vm.isDeletableUser(item) ? _vm.remove(item) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': _vm.isDeletableUser(item)
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }