<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Physical person") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <input v-model="searchFilter" class="min-w-300px form-control form-control-solid mr-4" type="text"
          :placeholder="$t('Search')">
        <euro-select v-model="itemFilter" class="min-w-200px mr-4 mb-0"
          :multiselect-props="multiselectProps"></euro-select>
        <router-link :to="{ name: 'create-physical-person' }" class="btn btn-light-primary font-weight-bolder">
          <div class="d-flex">
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
            </span>
            {{ $t("Create") }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="card-body detail">
      <datatable ref="datatableRef" :table-props="tableProps" :total="total" :options.sync="tableOptions"
        :per-page-options="perPageOptions">
        <!-- CELLS -->
        <template #[`cell.name`]="{ item }">
          <div class="text-capitalize d-flex align-items-center">
            <Avatar :avatar-image="item?.avatar_display" :avatar-text="`${item?.name?.[0]}${item?.surname?.[0]}`" />
            <div class="ml-3">
              <div class="font-weight-bold" :style="{ wordBreak: 'break-word' }">
                {{ `${item?.name} ${item?.surname}` }}
              </div>
            </div>
          </div>
        </template>

        <template #[`cell.type`]="{ item }">
          <div v-if="item.is_temporary_employee" class="label label-inline font-weight-bold label-light-warning">
            {{ $t("Temporary worker") }}
          </div>
          <div v-else-if="item.is_employee" class="label label-inline font-weight-bold label-light-success">
            {{ $t("Employee") }}
          </div>
          <div v-else class="label label-inline font-weight-bold label-light-primary">
            {{ $t("Standard person") }}
          </div>
          <div v-if="item.is_client_on_secondments" class="ml-3 label label-inline font-weight-bold label-light-info">
            {{ $t("Client") }}
          </div>
          <div v-if="item.is_partner_on_secondments" class="ml-3 label label-inline font-weight-bold label-light-info">
            {{ $t("Partner") }}
          </div>
          <div v-if="item.is_contractor_on_secondments" class="ml-3 label label-inline font-weight-bold label-light-info">
            {{ $t("Contractor") }}
          </div>
        </template>

        <template #[`cell.main_address`]="{ item }">
          <div v-if="item.physicalpersonaddress_set.length">
            <div class="font-weight-normal">{{ getMainAddress(item) }}</div>
            <div class="font-weight-normal text-muted">{{ getMainAddressDescription(item) }}</div>
          </div>
        </template>

        <template #[`cell.tel_contact`]="{ item }">
          <div v-if="item.telcontact_set.length">
            <div class="font-weight-normal">{{ getMainTel(item) }}</div>
            <div class="font-weight-normal text-muted">{{ getMainTelKind(item) }}</div>
          </div>
        </template>

        <template #[`cell.extraemail_set`]="{ item }">
          <div v-if="item.extraemail_set.length" class="max-width-column">
            <div class="font-weight-normal">{{ item.extraemail_set[0].email }}</div>
          </div>
          <div v-else></div>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div class="d-flex">
            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
            <b-button v-b-tooltip.hover :title="isDeletableTooltip(item)" class="btn btn-icon btn-light btn-sm"
              :class="{ 'btn-hover-primary': isDeletableUser(item) }"
              @click="isDeletableUser(item) ? remove(item) : null">
              <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': isDeletableUser(item) }">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </b-button>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
i.arrow {
  font-size: 0.7rem;
}

.per-page-select {
  max-width: 5rem;
}

.max-width-column {
  max-width: 200px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import PhysicalPersonTelephoneService from "@/core/services/physical-person/physical-person-telephone.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import Swal from "sweetalert2";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    Datatable,
  },
  data() {
    return {
      DateService,
      itemFilter: "ALL",
      // To edit
      selectOptions: [
        { value: "ALL", text: this.$t("All") },
        { value: "EMPLOYEE", text: this.$t("Employee") },
      ],
      fields: [
        {
          label: this.$t("Name"),
          key: "name",
          thClass: ["d-flex", "justify-content-left"],
          sortable: true,
        },
        { label: this.$t("Type"), key: "type", class: ["font-weight-bolder"], sortable: false },
        { label: this.$t("Mail"), key: "extraemail_set", class: ["font-weight-bolder"], sortable: false },
        { label: this.$t("Telephone"), key: "tel_contact", class: ["font-weight-bolder"] },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      items: [],
      total: 0,
      searchFilter: "",
      GENDERS: [],
      TELEPHONE_KINDS: [],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter,
        "no-sort-reset": true,
        responsive: true,
        "tbody-tr-class": this.trClass,
      };
    },
    multiselectProps() {
      return {
        options: this.selectOptions,
        searchable: false,
        showLabels: false,
        "allow-empty": false,
      }
    },
    /**
     Il seguente filtro viene utilizzato nella chiamata backend
     true  = persone fisiche di tipo employee
     false = persone fisiche di tipo non employee
     null  = tutte le persone fisiche
     */
    employeeFilter() {
      switch (this.itemFilter) {
        case "EMPLOYEE": {
          return true
        };
        default: {
          return null
        }
      }
    },
  },

  watch: {
    itemFilter() {
      this.refresh();
    }
  },

  async mounted() {
    try {
      const [genders, telephones] = await Promise.all([
        PhysicalPersonDetailService.getGenders(),
        PhysicalPersonTelephoneService.getKinds(),
      ]);
      this.GENDERS = genders.map((el) => ({ value: el.value, text: el.display_name }));
      this.TELEPHONE_KINDS = telephones.map((el) => ({ value: el.value, text: el.display_name }));
    } catch (err) {
      console.error(err);
      backendErrorSwal(err, this.$t("failed to init"));
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person") },
      { title: this.$t("Manage") },
    ]);
  },

  methods: {
    itemProvider(ctx, callback) {
      PhysicalPersonService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        employeeFilter: this.employeeFilter,
        fields: 'id,avatar_display,name,surname,is_employee,physicalpersonaddress_set,telcontact_set,extraemail_set,user_id,is_client_on_secondments,is_partner_on_secondments,is_contractor_on_secondments,is_temporary_employee,secondment_client_relations,secondment_contractor_relations,secondment_partner_relations'
      })
        .then((res) => {
          this.total = res.count;
          callback(res.results);
        })
        .catch((err) => {
          backendErrorSwal(err, this.$t("failed to load items"));
          callback([]);
        });

      return null;
    },

    refresh() {
      this.$refs.datatableRef.refresh();
    },

    edit(item) {
      this.$router.push({
        name: "detail-physical-person",
        params: { userID: item.id },
      });
    },

    remove(item) {
      console.log('@remove', item);
      Swal.fire({
        title: this.$t("Delete"),
        text: `${this.$t("Are you sure you want to delete")} ${item.name} ${item.surname}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(async (res) => {
        if (res.isConfirmed) {
          try {
            await PhysicalPersonService.delete(item.id);
            Swal.fire(this.$t("Success"), this.$t("Physical person deleted successfully"), "success");
            this.refresh();
          } catch (err) {
            console.error(err);
            backendErrorSwal(err?.response?.data?.error);
          }
        }
      });
    },

    getMainAddress(item) {
      const addresses = item.physicalpersonaddress_set;
      if (!addresses.length) {
        return "";
      }

      if (addresses.length === 1) {
        return addresses[0].address?.formatted ?? addresses[0].address.raw;
      }

      const address = addresses.find((el) => el.main);
      if (!address) {
        return "";
      }

      return address.address?.formatted ?? address.address.raw;
    },

    getMainAddressDescription(item) {
      const addresses = item.physicalpersonaddress_set;
      if (!addresses.length) {
        return "";
      }
      if (addresses.length === 1) {
        return addresses[0].description;
      }

      const address = addresses.find((el) => el.main);
      if (!address) {
        return "";
      }
      return address.description;
    },

    getMainTel(item) {
      const telephones = item.telcontact_set;
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return tel?.number || notMain?.number || "";
    },

    getMainTelKind(item) {
      const telephones = item.telcontact_set;
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return (
        this.TELEPHONE_KINDS.find((el) => el.value == tel?.kind)?.text ||
        this.TELEPHONE_KINDS.find((el) => el.value == notMain?.kind)?.text ||
        ""
      );
    },
    isDeletableTooltip(item) {
      if (item.is_employee) {
        return this.$t("Unable to delete a Employee")
      }
      if (item.user_id) {
        return this.$t("Unable to delete a User")
      }
      if (item.secondment_client_relations.length > 0 || item.secondment_partner_relations.length > 0 || item.secondment_contractor_relations.length > 0) {
        return this.$t("Unable to delete, used on Assignment")
      }
      return this.$t("Delete")
    },
    isDeletableUser(item) {
      if (item.is_employee) {
        return false;
      }
      if (item.user_id) {
        return false;
      }
      if (item.secondment_client_relations.length > 0) {
        return false;
      }
      if (item.secondment_partner_relations.length > 0) {
        return false;
      }
      if (item.secondment_contractor_relations.length > 0) {
        return false;
      }

      return true;
    }
  },
};
</script>
<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>